<template>
    <modal ref="modalEliminarCarpeta" titulo="Eliminar carpeta" :eliminar="true" :cargando="loading" :desactivar-guardar="false" :no-aceptar="true" @eliminar="eliminar">
        <div class="row mx-0 my-5">
            <div class="col-12 text-center f-600">
                ¿Está seguro de eliminar esta carpeta?
            </div>
            <div class="col-12 text-center my-3">
                Los datos y la información no podrán ser recuperados.
            </div>
        </div>
    </modal>
</template>

<script>
import Carpeta from '~/services/cursos/carpetas'

export default {
    data(){
        return {
            loading: false,
            id_carpeta: parseInt(this.$route.params.id_carpeta)

        }
    },
    methods: {
        toggle(){
            this.$refs.modalEliminarCarpeta.toggle();
        },
        async eliminar(){
            try {
                const {data: {data}} = await Carpeta.eliminarCarpeta({id_carpeta: this.id_carpeta})
                this.notificacion('Mensaje', 'Carpeta eliminada', 'success')
                this.$refs.modalEliminarCarpeta.toggle();
                this.$router.back()
            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>

<style>

</style>